exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-downloads-index-js": () => import("./../../../src/pages/downloads/index.js" /* webpackChunkName: "component---src-pages-downloads-index-js" */),
  "component---src-pages-downloads-old-js": () => import("./../../../src/pages/downloads/old.js" /* webpackChunkName: "component---src-pages-downloads-old-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-index-js": () => import("./../../../src/pages/iot/index.js" /* webpackChunkName: "component---src-pages-iot-index-js" */),
  "component---src-pages-ipc-index-js": () => import("./../../../src/pages/ipc/index.js" /* webpackChunkName: "component---src-pages-ipc-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog/post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-posts-js": () => import("./../../../src/templates/blog/posts.js" /* webpackChunkName: "component---src-templates-blog-posts-js" */),
  "component---src-templates-download-model-js": () => import("./../../../src/templates/DownloadModel.js" /* webpackChunkName: "component---src-templates-download-model-js" */),
  "component---src-templates-download-series-js": () => import("./../../../src/templates/DownloadSeries.js" /* webpackChunkName: "component---src-templates-download-series-js" */),
  "component---src-templates-iot-category-js": () => import("./../../../src/templates/IotCategory.js" /* webpackChunkName: "component---src-templates-iot-category-js" */),
  "component---src-templates-iot-model-js": () => import("./../../../src/templates/IotModel.js" /* webpackChunkName: "component---src-templates-iot-model-js" */),
  "component---src-templates-ipc-category-js": () => import("./../../../src/templates/IpcCategory.js" /* webpackChunkName: "component---src-templates-ipc-category-js" */),
  "component---src-templates-ipc-model-js": () => import("./../../../src/templates/IpcModel.js" /* webpackChunkName: "component---src-templates-ipc-model-js" */)
}

